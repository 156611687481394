import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import React, { ReactElement, useState } from 'react';
import { encodePassphrase, generateRoomId, randomString } from '../lib/client-utils';
import styles from '../styles/Home.module.css';

interface TabsProps {
  children: ReactElement[];
  selectedIndex?: number;
  onTabSelected?: (index: number) => void;
}

function Tabs(props: TabsProps) {
  const activeIndex = props.selectedIndex ?? 0;
  if (!props.children) {
    return <></>;
  }

  let tabs = React.Children.map(props.children, (child, index) => {
    return (
      <button
        className="lk-button"
        onClick={() => {
          if (props.onTabSelected) props.onTabSelected(index);
        }}
        aria-pressed={activeIndex === index}
      >
        {child?.props.label}
      </button>
    );
  });
  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabSelect}>{tabs}</div>
      {props.children[activeIndex]}
    </div>
  );
}

function DemoMeetingTab({ label }: { label: string }) {
  const router = useRouter();
  const [e2ee, setE2ee] = useState(false);
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));
  const startMeeting = () => {
    if (e2ee) {
      router.push(`/rooms/${generateRoomId()}#${encodePassphrase(sharedPassphrase)}`);
    } else {
      router.push(`/rooms/${generateRoomId()}`);
    }
  };
  return (
    <div className={styles.tabContent}>
      <p style={{ margin: 0 }}>Try LiveKit Meet for free with our live demo project.</p>
      <button style={{ marginTop: '1rem' }} className="lk-button" onClick={startMeeting}>
        Start Meeting
      </button>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <input
            id="use-e2ee"
            type="checkbox"
            checked={e2ee}
            onChange={(ev) => setE2ee(ev.target.checked)}
          ></input>
          <label htmlFor="use-e2ee">Enable end-to-end encryption</label>
        </div>
        {e2ee && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <label htmlFor="passphrase">Passphrase</label>
            <input
              id="passphrase"
              type="password"
              value={sharedPassphrase}
              onChange={(ev) => setSharedPassphrase(ev.target.value)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const fetchData = async (roomName: string) => {
  try {
    const response = await fetch(`https://livekit-api.doczo.dev/token/generate?room_name=${roomName}`);
    if (!response.ok) throw new Error('Network response was not ok');
    const data = await response.json();
    console.log('>> response data', data);
    const token = data.Token;
    if (!token) throw new Error('Token is not valid');
    return token;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

function CustomConnectionTab({ }: {}) {
  const router = useRouter();
  const { room, hq } = router.query;

  const [e2ee, setE2ee] = useState(false);
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));
  const [roomName, setRoomName] = useState(room || '');

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    // const serverUrl = formData.get('serverUrl');
    const serverUrl = 'wss://livekit.doczo.dev'; // TODO: replace with wss://meet.doczo.dev

    console.log('>> roomName', roomName);

    if (!roomName) {
      console.error('Room name not found');
      return;
    }

    const token = await fetchData(roomName as string);
    if (token) {
      router.push(`/custom/?liveKitUrl=${serverUrl}&token=${token}`);
    } else {
      console.error('Token not found');
    }

    // if (e2ee) {
    //   router.push(
    //     `/custom/?liveKitUrl=${serverUrl}&token=${token}#${encodePassphrase(sharedPassphrase)}`,
    //   );
    // } else {
    //   router.push(`/custom/?liveKitUrl=${serverUrl}&token=${token}`);
    // }

    // router.push(`/custom/?liveKitUrl=${serverUrl}&token=${token}`);
  };

  return (
    <form className={styles.tabContent} onSubmit={onSubmit} style={{ border: '1px solid #05294a' }}>
      {/* <input
        id="serverUrl"
        name="serverUrl"
        type="url"
        placeholder="LiveKit Server URL: wss://*.livekit.cloud"
        required
      /> */}
      {/* <textarea
        id="token"
        name="token"
        placeholder="Token"
        required
        rows={5}
        style={{ padding: '1px 2px', fontSize: 'inherit', lineHeight: 'inherit' }}
      /> */}
      <input
        id="roomName"
        name="roomName"
        style={{ backgroundColor: '#fff', borderColor: '#007AFF', color: '#000', height: '35px', border: '1px solid #007AFF', borderRadius: '0.25rem' }}
        value={roomName}
        type="text"
        placeholder="Room name"
        onChange={(e) => setRoomName(e.target.value)}
        required
      />
      {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <input
            id="use-e2ee"
            type="checkbox"
            checked={e2ee}
            onChange={(ev) => setE2ee(ev.target.checked)}
          ></input>
          <label htmlFor="use-e2ee">Enable end-to-end encryption</label>
        </div>
        {e2ee && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <label htmlFor="passphrase">Passphrase</label>
            <input
              id="passphrase"
              type="password"
              value={sharedPassphrase}
              onChange={(ev) => setSharedPassphrase(ev.target.value)}
            />
          </div>
        )}
      </div> */}

      <hr
        style={{ width: '100%', borderColor: 'rgba(255, 255, 255, 0.15)', marginBlock: '1rem' }}
      />
      <button
        style={{ paddingInline: '1.25rem', width: '100%', borderRadius: '0.50rem', border: 'none', backgroundColor: '#007AFF', color: '#fff', height: '50px', cursor: 'pointer' }}
        // className="lk-button"
        type="submit"
      >
        Connect
      </button>
    </form>
  );
}

export const getServerSideProps: GetServerSideProps<{ tabIndex: number }> = async ({
  query,
  res,
}) => {
  res.setHeader('Cache-Control', 'public, max-age=7200');
  const tabIndex = query.tab === 'custom' ? 1 : 0;
  return { props: { tabIndex } };
};

const Home = ({ tabIndex }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter();
  function onTabSelected(index: number) {
    const tab = index === 1 ? 'custom' : 'demo';
    router.push({ query: { tab } });
  }


  return (
    <>
      <main className={styles.main} data-lk-theme="default">
        <div className="header">
          <img src="https://www.doczo.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FlogoFull.5ebbef82.svg&w=384&q=75" alt="Doczo Meet" width="360" height="45" />
        </div>
        <CustomConnectionTab />
      </main>
      <footer data-lk-theme="default">
        Built by{' '}
        <a href="https://www.doczo.com" rel="noopener" target='_blank'>
          Doczo.com
        </a>
      </footer>
    </>
  );
};

export default Home;
